import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutMe from './components/AboutMe';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import ParticleBackground from './components/ParticleBackground';

function App() {
  const { i18n } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [experience, setExperience] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching starts

        // Fetch projects
        const projectsResponse = await fetch(
          `https://testing.loris-hutter.ch/api/projects.php?lang=${i18n.language}`
        );
        const projectsData = await projectsResponse.json();
        setProjects(Array.isArray(projectsData) ? projectsData : []); // Ensure projects is an array

        // Fetch experience
        const experienceResponse = await fetch(
          `https://testing.loris-hutter.ch/api/experience.php?lang=${i18n.language}`
        );
        const experienceData = await experienceResponse.json();
        setExperience(Array.isArray(experienceData) ? experienceData : []); // Ensure experience is an array

        setLoading(false); // Data fetched, stop loading
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchData();
  }, [i18n.language]); // Refetch data when the language changes

  if (loading) {
    return <div>Loading...</div>; // Replace with a spinner or placeholder UI
  }

  return (
    <div className="App">
      <ParticleBackground />
      <Navbar />
      <main>
        <Header />
        <AboutMe experience={experience} />
        <Skills projects={projects} />
        <Portfolio projects={projects} />
        {/* <ContactForm /> */}
      </main>
      <Footer />
    </div>
  );
}

export default App;
